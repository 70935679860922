@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  max-width: 1920px;
  padding: 3%;
  background: #f5f5f5;
  margin: auto;
}

a {
  text-decoration: none;
}

.App {
  height: 100%;
}

.App .menu {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.App .menu .left {
  width: 400px;
  display: flex;
  justify-content:space-around;
}

.App .menu .right {
  max-width: 400px;
  display: flex;
}

.App .menu a {
  color: #000000;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  font-size: 18px;
}

.top-contents {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
}

.top-contents .left-side {
  height: 100%;
  display: flex;
  width: 50%;
  height: 100%;
  float: left;
  align-items: center;
  justify-content: center;
}

.top-contents .right-side {
  height: 100%;
  display: flex;
  width: 50%;
  height: 100%;
  float: right;
  align-items: center;
  justify-content: flex-start;
}

.top-contents .top-illust {
  height: 100%;
  max-height: 800px;
  max-width: 1000px;
  animation: fadeIn 8s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

.top-contents .logo-text .name {
  color: #000000;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 96px;
  text-decoration: none;
  text-align: right;
  animation: slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.top-contents .logo-text .job {
  color: #000000;
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: ≈;
  font-size: 36px;
  text-decoration: none;
  text-align: right;
  animation: slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.about-contents {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.about-contents p {
  width: 60%;
  color: #000000;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  animation: fadeIn 2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

.about-contents .profile-image {
  margin: 150px;
  width: 350px;
  border-radius:50%;
  animation: fadeIn 1.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

.works-contents {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.works-contents .container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f5f5;
  width: 60%;
  max-width: 800px;
  height: 300px;
  margin: 40px auto;
  box-shadow: 0 3px 5px rgba(0,0,0,0.22);
  animation: slideIn 1s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}

.works-contents .container a {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}

.works-contents .container a .description {
  display: none;
}

.works-contents .container:hover a .description {
  display: flex;
  text-align: left;
  width:100%;
  height:100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgba(0,0,0,.7);
  padding: 30px;
}

.works-contents .container a .description .title {
  color: #fff;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
  font-size: 20px;
  line-height: 30px;
}

.works-contents .container a .description p{
  color: #fff;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.works-contents .container a .description .latest-released-at {
  color: #fff;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
  font-size: 16px;
  line-height: 20px;
}

.works-contents .container a .description .language {
  color: #fff;
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "GRAD" 0;
  font-size: 16px;
  line-height: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(180px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
  40%,100% {
    opacity: 1;
  }
}